// IIFE called when script is loaded
// Immediately invoked function expression
(function initModule() {
    const editableInputContainers = [ ...document.querySelectorAll( '.js-input-editable' ) ];

    if ( !editableInputContainers ) return;

    editableInputContainers.forEach( editableInputContainer => {
        const editableInput = editableInputContainer.querySelector( '.form-input' );
        const editButton = editableInputContainer.querySelector( '.btn-edit-input' );
        const confirmButton = editableInputContainer.querySelector( '.btn-confirm-input' );
        const dismissButton = editableInputContainer.querySelector( '.btn-dismiss-input' );
        let inputValue = editableInput.value;

        editButton.addEventListener( 'click', () => {
            editableInput.readOnly = false;
            editableInputContainer.classList.add( 'is-in-edit-mode' );
        } );

        confirmButton.addEventListener( 'click', () => {
            editableInput.readOnly = true;
            inputValue = editableInput.value;
            editableInputContainer.classList.remove( 'is-in-edit-mode' );
        } );

        dismissButton.addEventListener( 'click', () => {
            editableInput.readOnly = true;
            editableInput.value = inputValue;
            editableInputContainer.classList.remove( 'is-in-edit-mode' );
        } );
    } );

})();
